@import './palette.scss';

.mat-mdc-button {
  box-shadow: none !important;

  // styles for prymary button
  &.mat-mdc-raised-button.mat-primary {
    &:hover {
      background-color: $primary-button-hover;
    }

    &:active {
      background-color: $primary-button-active;
    }

    &[disabled] {
      background-color: $primary-button-disabled !important;
      color: $primary-button-disabled-text !important;
    }
  }

  // styles for prymary button
  &.mat-mdc-raised-button.mat-warn {
    &:hover {
      background-color: $warn-button-hover;
    }

    &:active {
      background-color: $warn-button-active;
    }

    &[disabled] {
      background-color: $warn-button-disabled !important;
      color: $warn-button-disabled-text !important;
    }
  }

  // styles for secondary button
  &.mat-mdc-raised-button {
    color: $white;
    background-color: $secondary-button;

    &:hover {
      background-color: $secondary-button-hover;
    }

    &:active {
      background-color: $secondary-button-active;
    }

    &[disabled] {
      background-color: $secondary-button-disabled !important;
      color: $secondary-button-disabled-text !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  &:hover .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}
