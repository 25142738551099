// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// @import "ag-grid-enterprise/styles/ag-theme-balham.css";
// @import 'ag-grid-community/styles/ag-theme-balham-dark.css';
// @import "prismjs/themes/prism.css";
@use '@angular/material' as mat;
@use './icon.scss';
@include mat.core();
@import "./button.scss";
@import "./tabs.scss";
// @import "prismjs/themes/prism";
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/animations/scale.css';

// // temporary until use theme generator once upgrade to v18
// $theme: mat.define-theme((color: (theme-type: light,
//         primary: mat.$azure-palette,
//         tertiary: mat.$blue-palette,
//       ),
//     ));
// @include mat.color-variants-backwards-compatibility($theme);

// body {
//   @include mat.all-component-themes($theme);
// }
.dense-button {
  @include mat.button-density(-5);

}

.dense-1 {
  @include mat.all-component-densities(-1);
}

.dense-2 {
  @include mat.all-component-densities(-2);
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

.dense-4 {
  @include mat.all-component-densities(-4);
}

.dense-5 {
  @include mat.all-component-densities(-5);
}


.dense-minimum {
  @include mat.all-component-densities(minimum);
}

// .density-settings-3 {
//   @include mat.paginator-density(-5);
//   // @include mat.icon-button-density(-3);
//   // @include mat.button-density(-3);
//   // @include mat.icon-density(-3);
// }

// .mini-assignment-paginator {
//   ::ng-deep button.mat-mdc-tooltip-trigger {
//     @include mat.icon-button-density(-5);
//   }
// }

.multi-line-snackbar {
  white-space: pre-wrap
}

.origin-package {
  background-color: #d7e3ff;
  color: #005cbb !important;
}

.origin-plan {
  background-color: #edefe2;
  color: #4c6127 !important;
}

.origin-measurement {
  background-color: #fcebe1;
  color: #895019 !important;
}

.cell-span-row-spanning {
  background-color: #ffffff;
  background-color: #2244cc44;
  // background: white;
  // border-left: 1px solid lightgrey !important;
  // border-right: 1px solid lightgrey !important;
  // border-bottom: 1px solid lightgrey !important;
}

.number-cell {
  text-align: right;
  justify-content: flex-end;
}

// .ag-header-cell-label {
//   justify-content: center;
// }

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;

  // font-family: sans-serif;
  font-family: var(--font-family) !important; // bootstrap overriding my font family?
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.full-height {
  height: 100%;
}

.full-width {
  min-width: 100% !important;
}

.text-left {
  text-align: left !important;
}


// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   background-color: rgb(253, 251, 251);
//   // border-left: 0.5px solid lightgray;
// }

// ::-webkit-scrollbar-track {
//   // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   // background-color: #f5f5f5;
//   // background-color: #f0f3fd;
//   background-color: white;
//   // border-left: 0.5px solid lightgray;
//   // border-right: 0.5px solid lightgray;
// }

// :hover::-webkit-scrollbar-thumb {
//   // border-radius: 10px;
//   background-color: rgb(230, 230, 230);
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: rgb(184, 184, 184);
//   // background: rgb(70, 107, 230);
//   // background: linear-gradient(180deg,
//   //     rgba(70, 107, 230, 1) 35%,
//   //     rgba(112, 150, 248, 1) 62%);
// }






.mat-mdc-tooltip {
  font-size: 12px !important;
}

.payment-presentation-target .p-splitter-panel-nested {
  overflow: hidden;
}

.payment-presentation-target .p-splitter-gutter {
  background-color: #e5ebfb;
}

.panel-target-sum {
  margin: 10px;
}

.panel-target-sum .p-panel-header {
  cursor: pointer;
}

.panel-target-sum .p-panel-content {
  padding: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.d-form-field div.mat-form-field-wrapper {
  margin: 0px;
  padding-bottom: 0px;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.payee-option div.mat-list-text {
  flex-direction: row !important;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis; //not work?
}

.transaction-paginator div.mat-mdc-paginator-container {
  flex-wrap: nowrap;
}

.custom-tooltip {
  overflow: visible;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p {
  font-weight: bold;
}

// if we want vertical grid lines:
// .ag-theme-balham .ag-header-cell,
// .ag-theme-balham .ag-header-group-cell,
// .ag-theme-balham .ag-ltr .ag-cell {
//   border-right: 1px solid #63757e;
// }

.locked-col-pin {
  background: rgba(221, 221, 221, 0.493);
}

.lock-pinned {
  background: #ddd;
}

.opened {
  background: #cfdcfe;
}

// div.mini-map {
//   top: 20px;
//   bottom: auto !important;
//   // left: auto !important;
//   // right: 40px !important;
// }
app-tab-group mat-tab-group.mat-mdc-tab-group-min {
  min-height: 85vh;
}

app-roll-hierarchy div#tree div hr,
app-payee-hierarchy div#tree div hr {
  margin: revert;
}

app-payee-hierarchy div#tree {
  height: calc(100vh - 134px);
}

app-roll-hierarchy div.boc-chart-menu,
app-payee-hierarchy div.boc-chart-menu {
  min-width: auto;
}

// app-node-detail mat-dialog-container.mat-dialog-container {
//   resize: both;
//   overflow: auto;
// }
app-roll-hierarchy div#tree {
  height: calc(100vh - 180px);
}

app-roll-hierarchy .mat-mdc-tab-group {
  flex-direction: row;
  height: calc(100vh - 180px);
}

app-payee-hierarchy .mat-mdc-tab-group {
  flex-direction: row;
  height: calc(100vh - 134px);
}

app-roll-hierarchy .mat-mdc-tab-header,
app-payee-hierarchy .mat-tab-header {
  border-bottom: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-roll-hierarchy .mat-tab-header-pagination,
app-payee-hierarchy .mat-tab-header-pagination {
  display: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-roll-hierarchy .mat-tab-labels,
app-payee-hierarchy .mat-tab-labels {
  flex-direction: column;
}

app-roll-hierarchy .mat-ink-bar,
app-payee-hierarchy .mat-ink-bar {
  height: 100% !important;
  left: 98% !important;
  display: block;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-roll-hierarchy .mat-tab-body-wrapper,
app-payee-hierarchy .mat-tab-body-wrapper {
  flex: 1 1 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
app-roll-hierarchy .mat-tab-label,
app-payee-hierarchy .mat-tab-label {
  min-width: 50px;
}

app-roll-hierarchy .mat-mdc-tab-body,
app-payee-hierarchy .mat-tab-body {
  padding: 0px;
}

app-manager .p-toast-node-click {
  z-index: 1;
}

div#tree .node rect {
  fill: #e0e0e0;
}

div#tree svg g.node.prior rect {
  // fill: #ffc92896;
  fill: #d4edda;
  // fill: #d7e4f9;
}

// app-payee-search div mat-dialog-container.mat-dialog-container {
//   height: inherit;
// }
.allow-cr {
  white-space: pre;
}

.node-info-p-toast {
  background-color: #e1cfe7;
  border: solid #8a427a;
  border-width: 0 0 0 6px;
  color: #2c1e30;

  .p-toast-icon-close {
    color: #2c1e30;
  }
}

app-roll-hierarchy .custom-p-dropdown-styles {
  // background-color: lightblue;
  min-width: 230px;
  // width: 230px;
  /* width: 300px; */
  // width: 14rem;
}

// app-roll-hierarchy .custom-p-dropdown-panel-styles {
//   // background-color: aquamarine;
// }
app-manager .skeleton-loading-drawer,
app-payee-hierarchy .skeleton-loading,
app-roll-hierarchy .skeleton-loading {
  opacity: 0.8;
  position: fixed;
  z-index: 100;
  background-color: rgba(181, 201, 220, 0.651);
  // height: calc(100vh - 180px);
  // height: 100%;
  // width: 100%;
  // pointer-events: none;
  // opacity: 0.2;
  cursor: wait;
  // user-select: none;
}

// app-manager .skeleton-loading-drawer {
//   opacity: 0.8;
//   position: fixed;
//   z-index: 100;
//   background-color: lightslategray;
//   // pointer-events: none;
//   cursor: wait;
// }
app-manager mat-drawer.skeleton-loading-no-event {
  // pointer-events: none;
  cursor: wait;
}

// app-manager .skeleton-loading-drawer {
//   opacity: 0.8;
//   position: fixed;
//   z-index: 100;
//   background-color: lightslategray;
//   cursor: wait;
// }
// app-manager mat-drawer.skeleton-loading-no-event {
//   user-select: none;
//   pointer-events: none;
//   cursor: wait;
// }
// app-roll-hierarchy .p-divider-left {
//   // color: rgb(70, 107, 230);
//   background-color: #8a427a;
//   border: black 2px solid;
//   border-top-color: red;
// }
app-payee-hierarchy .p-divider-roll-current.p-divider-horizontal:before,
app-roll-hierarchy .p-divider-roll-current.p-divider-horizontal:before {
  border-top: 1.2px solid #8be4a0;
}

app-payee-hierarchy .p-divider-roll-past.p-divider-horizontal:before,
app-roll-hierarchy .p-divider-roll-past.p-divider-horizontal:before {
  border-top: 1.2px solid #ffe07b;
}

app-payee-hierarchy .p-divider-roll-adding.p-divider-horizontal:before,
app-roll-hierarchy .p-divider-roll-adding.p-divider-horizontal:before {
  border-top: 1.2px solid rgb(101, 156, 211);
  width: 100%;
}

// app-payee-hierarchy
// app-payee-hierarchy .p-sidebar-custom .p-sidebar-content {
//   height: 100%;
// }
app-payee-hierarchy .p-sidebar-custom .p-sidebar-content,
app-roll-hierarchy .p-sidebar-custom .p-sidebar-content {
  height: 100%;
  padding: 0.5rem;
}

// app-payee-hierarchy .p-sidebar-custom .p-sidebar-mask,
// app-roll-hierarchy .p-sidebar-custom .p-sidebar-mask {
//   height: 100%;
// }

app-payee-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-track,
app-roll-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

app-payee-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar,
app-roll-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgb(253, 251, 251);
}

app-payee-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-thumb,
app-roll-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(180, 180, 180);
}

app-payee-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-thumb:hover,
app-roll-hierarchy .p-sidebar-custom .p-sidebar-content::-webkit-scrollbar-thumb:hover {
  // background-color: #466be6;
  background: rgb(70, 107, 230);
  background: linear-gradient(180deg,
      rgba(70, 107, 230, 1) 35%,
      rgba(112, 150, 248, 1) 62%);
}

app-formula-rule {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

// payment-presentation .payment-nodes-breadcrumb-wrapper {

//   /* width */
//   ::-webkit-scrollbar {
//     width: 3px;
//     height: 3px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #888;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }

// }

// app-password-reset
app-auth .p-toast-auth {
  z-index: 1;
}

// mark[data-markjs="true"],
// mark.mark {
//   background: orange;
//   color: black;
// }

$compensation-height-calc: calc(100vh - 184px);

app-compensation {
  p-sidebar {
    .cp-sidebar-wide {
      width: 60em !important;
      transition: width 0.15s ease-out;
      height: $compensation-height-calc;
      margin-top: 50px;
    }

    .cp-sidebar-normal {
      width: 30em !important;
      transition: width 0.25s ease-in;
      height: $compensation-height-calc;
      margin-top: 50px; // have to put this because of priemng's weird append bug
    }
  }

  // div.p-treeselect-panel,
  p-treeselect[styleClass="custom-tree-select"] {
    .p-inputwrapper {
      width: 20rem;

      .p-treeselect-label {
        // display: block;
        // white-space: nowrap;
        // cursor: pointer;
        // //    overflow: hidden;
        overflow: auto;
        text-overflow: clip;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        p-tag {
          span {
            color: darkslategray;
          }

          .tree-sel-pkg {
            background-color: rgba(197, 232, 197, 0.78);
          }

          .tree-sel-plan {
            background-color: rgba(250, 209, 193, 0.665);
          }

          .tree-sel-meas {
            background-color: rgba(194, 227, 248, 0.742);
          }
        }
      }
    }

    .p-tree {
      .p-tree-container .p-treenode {
        .p-treenode-content {
          height: 32px;

          &.p-highlight {
            background: none;
            color: #3f51b5;

            .p-treenode-icon {
              // color: #495057;
              color: #3f51b5;
            }
          }

          &:focus {
            outline: 0 none;
            outline-offset: 0;
            //box-shadow: 0 0 0 0.2rem #a6d5fa;
            box-shadow: none;
            background: rgba(0, 0, 0, 0.04);
          }

          .p-tree-toggler {
            display: none;
          }

          .p-checkbox {
            margin-left: 0.5rem;

            .p-checkbox-box.p-highlight {
              border-color: #3f51b5;
              background: #3f51b5;
            }
          }
        }
      }
    }
  }
}

app-manager p-toast[key="compensationToastKey"] div.p-toast {
  z-index: 1;

  .p-toast-message .p-toast-message-content .p-toast-detail {
    overflow-wrap: break-word;
    max-width: 18.5rem;
    //  word-wrap: break-word;
    // word-break: break-all;
  }

}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 15px;
  font-size: 100%;
  left: 0;
  width: 3em;
  letter-spacing: -1px;
  border-right: 1px solid rgb(179, 179, 179);
  user-select: none;
}

.line-numbers-rows>span {
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows>span:before {
  content: counter(linenumber);
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

// payee-hierarchy2 ag-grid contextmenu:
.redFont {
  color: red;
}

// roll hierarchy 2 mat form input
.dense-form-field {
  @include mat.form-field-density(-3);
}

// app-manager p-toast div.p-toast
// div.codejar-wrap div.codejar-linenumbers {
//   mix-blend-mode: normal !important;
//   background-color: lightgray !important;
// }
