// @use '@angular/material' as mat;
// @import '~@../../node_modules/@angular/material/_theming.scss';
// @import "@angular/material/theming";

$mat-palette-inlogic: (
  50: #e9f1ff,
  100: #c7dcff,
  200: #a2c5ff,
  300: #7cadff,
  400: #609cff,
  500: #448aff,
  600: #3e82ff,
  700: #3577ff,
  800: #2d6dff,
  900: #1f5aff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #cedaff,
  A700: #b5c6ff,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-palette-inlogic-warn: (
  50: #fee8e7,
  100: #fcc7c3,
  200: #faa19b,
  300: #f77b72,
  400: #f65f54,
  500: #f44336,
  600: #f33d30,
  700: #f13429,
  800: #ef2c22,
  900: #ec1e16,
  A100: #ffffff,
  A200: #ffe9e9,
  A400: #ffb8b6,
  A700: #ff9f9c,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-palette-inlogic-accent: (
  50: #fef9ed,
  100: #fcf0d1,
  200: #fbe7b3,
  300: #f9dd95,
  400: #f7d57e,
  500: #f6ce67,
  600: #f5c95f,
  700: #f3c254,
  800: #f2bc4a,
  900: #efb039,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffefd3,
  A700: #ffe6b9,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// $app-primary: mat.define-palette($mat-palette-inlogic, 500, 700);
// $app-accent:  mat.define-palette($mat-palette-inlogic-accent, 500);
// $app-warn: mat.define-palette($mat-palette-inlogic-warn, 500);

// Create the theme object (a Sass map containing all of the palettes).
// $app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

// Exports for other custom components to use
// $primary: mat.get-color-from-palette(map-get($app-theme, primary));
// $warn: mat.get-color-from-palette(map-get($app-theme, warn));
// $accent: mat.get-color-from-palette(map-get($app-theme, accent));

// colors for tabs state
$tab-backgroud-color: #d7e4f9; //#f3f8ff
$tab-backgroud-color-hover: #fafafa;

// colors for buttons state
$warn-button-hover: #ed2521;
$warn-button-active: #d41f20;
$warn-button-disabled: #ffada8;
$warn-button-disabled-text: #dd938f;

$primary-button-hover: #2978ed;
$primary-button-active: #236dd2;
$primary-button-disabled: #aaccfe;
$primary-button-disabled-text: #8aa2ca;

$secondary-button: #78909c;
$secondary-button-hover: #66808d;
$secondary-button-active: #5a737d;
$secondary-button-disabled: #c1cdd1;
$secondary-button-disabled-text: #99a4a8;

//main colors to use in components
$black: #222222;
$white: #ffffff;

// colors for sidebar and header
$charade: #282b35;
$charade-light: #2c303b;
$charade-dark: #232730;
$slate-gray: #788195;
$dove-gray: #626262;
$mercury: #e6e6e6;
$gallery: #f0f0f0;

// colour palette variables:
$__navy: #001e6c;
$__lightnavy: #035397;
$__plainblue: #5089c6;
$__orange1: #ffaa4c;
$__invisibleblue: rgb(203, 213, 247);
$__white1: #f0f0f0;
$__white2: #f5f4f4bd;
$__whitegray1: #c1c1c1;
$__whiteblue1: #e5ebfb;

$__color1: #001e6c;
$__color2: #035397;
$__color3: #5089c6;
$__color4: #ffaa4c;
$__color5: rgb(203, 213, 247);
$__color6: #f0f0f0;
$__color7: #f5f4f4bd;
$__color8: #c1c1c1;
$__color9: #e5ebfb;
$__color10: rgb(52, 140, 167);