@import "./palette.scss";

// .mat-tab-header {
//   border-bottom: 0 !important; // why did i add this? for which component?
// }

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-labels {
  background-color: $white;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    position: relative;

    height: 36px !important;

    padding: 0 !important;

    // width: 100%; // why did i add this? for which component?
    // padding-left: 12px !important;
    // padding-right: 10px !important;

    justify-content: space-between !important;

    background-color: transparent;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    &.mat-tab-label-active {
      background-color: $tab-backgroud-color;
    }

    &:hover {
      background-color: $tab-backgroud-color-hover;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-content {
      width: 100%;

      .mat-icon {
        font-size: 18px;

        width: 18px;
        height: 18px;

        // position: absolute;
        // right: 12px;
      }
    }
  }
}